<template>
  <van-overlay :show="isShow">
    <div class="table">
      <div class="content-box">
        <slot name="topBox"></slot>
      </div>

      <div class="item-box" v-if="!isUpdate">
        <div class="btn-cancel" @click="$emit('onBack')">取消</div>
        <div class="btn-confirm" @click="$emit('onFinish')">确定</div>
      </div>

      <div class="item-box" v-else>
        <div class="btn-cancel" @click="$emit('onBack')">取消</div>
        <div class="btn-del" @click="$emit('onDel')">删除</div>
        <div class="btn-confirm" @click="$emit('onUpdate')">确定</div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay } from "vant";
export default {
  props: ["isUpdate", "isShow"],
  components: {
    [Overlay.name]: Overlay,
  },
};
</script>

<style scoped>
.table {
  position: relative;
  top: 25%;
  width: 90%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 8px;
}

.content-box {
  padding: 12px;
  margin: 10px 0 30px;
}

.item-box {
  display: flex;
  padding: auto;
  align-items: center;
  text-align: center;
  height: 50px;
  line-height: 50px;
}

.btn-cancel {
  flex: 1;
  border-top: 1px solid #e2dddd;
  color: #999999;
}

.btn-del {
  flex: 1;
  height: 100%;
  color: #FFFFFF;
  background: #f98c39;
  border-right: 1px solid #fff;
}

.btn-confirm {
  flex: 1;
  height: 100%;
  color: #FFFFFF;
  background: #658CF1;
}
</style>
